// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyD_aBkZiHw_ZNUyyjtr2bdECoZElP0Qt8g",
  authDomain: "yuze-sliceboi.firebaseapp.com",
  projectId: "yuze-sliceboi",
  storageBucket: "yuze-sliceboi.appspot.com",
  messagingSenderId: "882926534",
  appId: "1:882926534:web:1a972d4ac5fe34e04c08c7"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }

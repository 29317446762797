import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>10 High Street, Yarm,<br />DL3 D74</p>

            <a href="tel:+441642 290806" class="phone-link">01642 290 806</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>
                Mon-Sat: 11:30am – 9pm<br />
                Sun: Closed
              </p>
          </div>
          <div class="col-md-8">
            <iframe
            class="map"
            title="Sliceboi's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2312.7257684452848!2d-1.3100727!3d54.5735847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e92ee8aae66af%3A0x8772e243b6c922c7!2s124%20Norton%20Rd%2C%20Stockton-on-Tees%20TS20%202AQ!5e0!3m2!1sen!2suk!4v1716303808713!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;